import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, CircularProgress, Typography, useMediaQuery,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import BarCode from '../../../assets/imgs/payment/barcode.svg';
import i18n from '../../../common/i18n';
import PayButtonAction from './PayButtonAction';
import { useAuth } from '../../../contexts/AuthContext';
import { getPaymentVoucherFile } from '../../../api/selfieWebService';
import { usePayInstallmentContext } from '../../../contexts/PayInstallmentContext';
import { generateLinkWithFileInResponse } from '../../../utils/functionsUtil';
import { useToaster } from '../../../contexts/ToasterContext';

const PayCash = ({ goBack }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { showToast } = useToaster();
  const { currentUser } = useAuth();
  const { selectedLoan } = usePayInstallmentContext();
  const [loading, setLoading] = useState(false);

  const downloadPaymentVoucher = async () => {
    try {
      setLoading(true);
      const res = await getPaymentVoucherFile(currentUser.customerId, selectedLoan.loanInfo.externalId);
      generateLinkWithFileInResponse(res);
    } catch (error) {
      showToast(i18n.PayCash.errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography align="left" style={{ fontSize: '16px', fontWeight: 600, marginBottom: '10px' }}>
        {i18n.PayCash.title}
      </Typography>
      <Box>
        <img src={BarCode} alt="Código de barras" style={{ marginBottom: '5px', marginTop: '25px' }} />
      </Box>
      <Typography align="left" style={{ fontSize: '14px', marginTop: '20px' }}>{i18n.PayCash.description}</Typography>
      <Alert severity="warning" style={{ marginTop: '28px' }}>
        <Typography align="left" style={{ fontSize: '14px', fontWeight: 700 }}>{i18n.PayCash.alert[0]}</Typography>
        <Typography align="left" style={{ fontSize: '14px' }}>{i18n.PayCash.alert[1]}</Typography>
      </Alert>
      <Typography align="left" style={{ fontSize: '14px', fontWeight: 600, margin: '24px 0px' }}>{i18n.PayCash.noProof}</Typography>
      <PayButtonAction isMobile={isMobile} goBack={goBack}>
        <Button
          onClick={downloadPaymentVoucher}
          variant="outlined"
          color="primary"
          fullWidth
          endIcon={loading && <CircularProgress size={20} />}
        >
          {i18n.PayCash.cta}
        </Button>
      </PayButtonAction>
    </>
  );
};

PayCash.propTypes = {
  goBack: PropTypes.func.isRequired,
};

export default PayCash;
