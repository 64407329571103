import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import {
  Box, Button, Container, Grid, RadioGroup,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ArrowBack } from '@material-ui/icons';
import i18n from '../common/i18n';
import imgOnline from '../assets/imgs/pago-online.svg';
import RadioButtonLoan from './RadioButtonLoan';
import { moneyFormatter } from '../utils/formatterUtil';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import RadioButtonDebtor from './RadioButtonDebtor';
import TextInput from './common/TextInput';
import PaymentMethodExplanation from './PaymentMethodExplanation';
import LoadingButton from './common/LoadingButton';
import CopyToClipboardButton from './common/CopyToClipboardButton';
import { getPayPartialAmountSchema } from '../forms/schemaValidations/schemaValidations';
import { getCvuPayment } from '../api/selfieWebService';
import { fillMessageWith } from '../utils/functionsUtil';

const useStyles = makeStyles((theme) => ({
  principalColor: {
    color: theme.palette.primary.main,
  },
}));

const PaymentSelection = ({
  loans, onSubmit, paymentMethodKey,
  onResetMethodSelection, loading,
}) => {
  const classes = useStyles();
  const [paymentOptionSelected, setPaymentOptionSelected] = useState(undefined);
  const [amountToPay, setAmountToPay] = useState(undefined);
  const [cvuToPay, setCvuToPay] = useState(undefined);
  const [punitiveAmount, setPunitiveAmount] = useState(undefined);

  const {
    customerAccountStatus: {
      customerIsUpToDate = true,
      totalDebt = 0,
    } = {},
  } = useCustomerAccountStatus() || {};

  const methods = useForm({
    resolver: yupResolver(getPayPartialAmountSchema(totalDebt)),
  });

  const watchPartialAmount = methods.watch('partialAmount');

  const getPunitive = (loan) => {
    let punitive = 0;
    loan.installments.forEach((installment) => {
      installment.charges.forEach((charge) => {
        if (charge.type === 'Punitive') {
          punitive += charge.amount;
        }
      });
    });
    return punitive;
  };

  const handleChange = async (event) => {
    setPaymentOptionSelected(event.target.value);
    const loanSelected = loans.find((loan) => loan.externalId === event.target.value);
    if (loanSelected) {
      methods.setValue('optionToPay', event.target.value);
      const punitive = getPunitive(loanSelected);
      setPunitiveAmount(punitive);
      setAmountToPay(loanSelected.currentInstallment.totalAmount + punitive);
      if (loanSelected.productId === 46) {
        setAmountToPay(loanSelected.revolvingLineAmount);
      }
      const res = await getCvuPayment(loanSelected.externalId);
      if (res.data) {
        setCvuToPay(res.data);
      }
    } else {
      methods.setValue('optionToPay', event.target.value);
      if (event.target.value === 'totalPayment') {
        methods.setValue('partialAmount', undefined);
      }
      setAmountToPay(totalDebt);
    }
  };

  const showAmountToPay = () => {
    if (watchPartialAmount !== undefined) {
      return moneyFormatter(watchPartialAmount);
    }
    return moneyFormatter(amountToPay);
  };

  const getPrimaryCTALabelByPaymentMethod = () => {
    switch (paymentMethodKey) {
      case i18n.payInstallmentOnlineKey: return i18n.payInstallmentOnlinePrimaryCTA;
      case i18n.payInstallmentCashKey: return i18n.payInstallmentCashPrimaryCTA;
      case i18n.payInstallmentBankTrxKey: return i18n.payInstallmentBankTrxPrimaryCTA;
      case i18n.payInstallmentEPaymentKey: return i18n.payInstallmentEPaymentPrimaryCTA;
      default: return null;
    }
  };

  const getPrimaryCTAIcon = () => {
    if (paymentMethodKey === i18n.payInstallmentCashKey) {
      return (<GetAppIcon />);
    }
    return null;
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Container>
            <Box m={6} />
            <Typography variant="h2">
              {customerIsUpToDate === true
                ? i18n.payInstallmentAlDiaTitle : i18n.payInstallmentDebtorTitle}
            </Typography>
            <Box m={6} />
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} md={4}>
                <RadioGroup
                  aria-label="amount"
                  name="optionToPay"
                  value={paymentOptionSelected}
                  onChange={handleChange}
                >
                  {customerIsUpToDate === true || paymentMethodKey === i18n.payInstallmentBankTrxKey ? (
                    loans.map((loan) => (
                      <RadioButtonLoan
                        key={loan.externalId}
                        checked={loan.externalId === paymentOptionSelected}
                        loanId={loan.externalId}
                        installmentCount={loan.installmentCount}
                        currentInstallment={loan.currentInstallment}
                        punitive={getPunitive(loan)}
                        loan={loan}
                      />
                    ))
                  ) : (
                    <>
                      <RadioButtonDebtor
                        value="totalPayment"
                        title={i18n.payInstallmentDebtorTotal}
                        checked={paymentOptionSelected === 'totalPayment'}
                      />
                      <RadioButtonDebtor
                        value="partialPayment"
                        title={i18n.payInstallmentDebtorPartial}
                        subtitle={i18n.payInstallmentDebtorPartialSubtitle}
                        checked={paymentOptionSelected === 'partialPayment'}
                      />
                    </>
                  )}
                </RadioGroup>
              </Grid>
            </Grid>
            {customerIsUpToDate === false && paymentOptionSelected === 'partialPayment' && (
              <Grid container justify="center">
                <Grid item xs={12} md={4}>
                  <TextInput
                    name="partialAmount"
                    mandatory
                    label={i18n.payInstallmentPartialAmountLabel}
                    fullWidth
                    helperText={i18n.payInstallmentPartialAmountHelpText}
                  />
                </Grid>
              </Grid>
            )}
            {amountToPay !== undefined && (
              <>
                <Box m={8} />
                <Grid container justify="center">
                  <Grid item xs={12} md={6}>
                    <img src={imgOnline} height="100px" alt="Pago online" />
                    <Box m={2} />
                    <Typography variant="h4">
                      {i18n.payInstallmentGoingToPay}
                    </Typography>
                    <Typography variant="h4" className={classes.principalColor}>
                      {showAmountToPay()}
                    </Typography>
                    {punitiveAmount > 0 && (
                      <Typography style={{ color: 'grey' }}>
                        {fillMessageWith(i18n.payInstallmentGoingToPayWithPunitive, moneyFormatter(punitiveAmount))}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </>
            )}

            <Box m={6} />
            {paymentOptionSelected && (
              <>
                { paymentMethodKey === i18n.payInstallmentBankTrxKey && cvuToPay ? (
                  <>
                    <Grid container justify="center">
                      <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
                        <Typography style={{ fontSize: '16px' }}>
                          <b>{i18n.payInstallmentCvuForLoanTitle}</b>
                        </Typography>
                        <Typography style={{ fontSize: '15px' }}>
                          {i18n.payInstallmentCvuForLoanSubTitle}
                        </Typography>
                        <Box m={2} />
                        <Typography style={{
                          fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                        }}
                        >
                          <span>
                            <b>CVU: </b>
                            {cvuToPay}
                          </span>
                          <CopyToClipboardButton value={cvuToPay} />
                        </Typography>
                        <Box m={2} />
                        <Alert icon={false} severity="info">
                          <Typography style={{ fontSize: '14px' }}>
                            <b>{i18n.payInstallmentCvuForLoan_1}</b>
                          </Typography>
                          <Typography style={{ fontSize: '12px' }}>
                            {i18n.payInstallmentCvuForLoan_2}
                          </Typography>
                        </Alert>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <PaymentMethodExplanation paymentMethodKey={paymentMethodKey} />
                )}
              </>
            )}

            <Box m={3} />
            <Grid container justify="center">
              <Grid item xs={12} md={4}>
                {paymentOptionSelected && (
                  <>
                    <LoadingButton
                      startIcon={getPrimaryCTAIcon()}
                      style={{ width: '100%' }}
                      variant="contained"
                      color="primary"
                      type="submit"
                      loading={loading}
                    >
                      {getPrimaryCTALabelByPaymentMethod()}
                    </LoadingButton>
                    <Box m={3} />
                  </>
                )}
                <Button
                  startIcon={<ArrowBack />}
                  onClick={onResetMethodSelection}
                >
                  {i18n.payInstallmentSecondaryCTA}
                </Button>
              </Grid>
            </Grid>
            <Box m={6} />
          </Container>
        </form>
      </FormProvider>
    </>
  );
};

PaymentSelection.propTypes = {
  loans: PropTypes.arrayOf(PropTypes.shape({
    externalId: PropTypes.string.isRequired,
    currentInstallment: PropTypes.shape({ totalAmount: PropTypes.number.isRequired }).isRequired,
  })).isRequired,
  onSubmit: PropTypes.func.isRequired,
  paymentMethodKey: PropTypes.string.isRequired,
  onResetMethodSelection: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PaymentSelection;
