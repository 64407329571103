import PropTypes from 'prop-types';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import { Typography, makeStyles } from '@material-ui/core';
import i18n from '../common/i18n';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'grid',
  },
  graph: {
    gridArea: '1 / 1 / 3 / 3',
  },
  label: {
    gridArea: '2 / 1 / 3 / 3',
    color: '#e72064',
  },
  labelText: {
    fontSize: '14px',
    fontWeight: '600',
    fontFamily: 'Open Sans',
  },
  labelAmount: {
    fontSize: '20px',
    fontWeight: '800',
    fontFamily: 'Poppins',
  },
}));

const RevolvingLineProgressBar = ({ maxAmount, remainingAmount }) => {
  const classes = useStyles();
  const { isRevolvinLineEnabled } = useCustomerAccountStatus();

  const resolvePercentage = () => {
    if (isRevolvinLineEnabled()) {
      return ((maxAmount - remainingAmount) / maxAmount) * 100;
    }
    return 0;
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.graph}>
          <SemiCircleProgressBar
            percentage={resolvePercentage()}
            stroke="#e72064"
            strokeWidth={24}
          />
        </div>
        <div className={classes.label}>
          <Typography className={classes.labelText}>
            {i18n.RevolvingLine.Detail.Header.remainingAmount}
          </Typography>
          <Typography className={classes.labelAmount}>
            {moneyFormatterWithoutDecimals(isRevolvinLineEnabled() ? remainingAmount : 0)}
          </Typography>
        </div>
      </div>
    </>

  );
};

RevolvingLineProgressBar.propTypes = {
  maxAmount: PropTypes.number.isRequired,
  remainingAmount: PropTypes.number.isRequired,
};

export default RevolvingLineProgressBar;
