import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, CircularProgress, Typography, useMediaQuery,
} from '@material-ui/core';
import i18n from '../../../common/i18n';
import PayButtonAction from './PayButtonAction';
import { usePayInstallmentContext } from '../../../contexts/PayInstallmentContext';
import { useToaster } from '../../../contexts/ToasterContext';
import { mercadoPagoPayment } from '../../../api/selfieWebService';

const PayMP = ({ goBack }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { showToast } = useToaster();
  const { selectedLoan } = usePayInstallmentContext();
  const [loading, setLoading] = useState(false);

  const goToMP = async () => {
    try {
      setLoading(true);
      // TODO: ver bien de donde obtener el monto a pagar una vez definidos los SPs con la info.
      const amountToPay = 999999;
      const { data } = await mercadoPagoPayment(selectedLoan.loanInfo.externalId, amountToPay);
      if (data.urlToRedirect) {
        window.open(data.urlToRedirect, '_self');
      } else {
        showToast(i18n.PayMP.errorMessage, 'error');
      }
    } catch (error) {
      showToast(i18n.PayMP.errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography align="left" style={{ fontSize: '14px' }}>{i18n.PayMP.description}</Typography>
      <Typography align="left" style={{ fonSize: '14px', fontWeight: 600, marginTop: '16px' }}>{i18n.PayMP.redirect}</Typography>
      <Typography align="left" style={{ fonSize: '14px', fontWeight: 600, margin: '16px 0px' }}>{i18n.PayMP.noProof}</Typography>
      <PayButtonAction isMobile={isMobile} goBack={goBack}>
        <Button
          onClick={goToMP}
          variant="contained"
          color="primary"
          fullWidth
          endIcon={loading && <CircularProgress size={20} />}
        >
          {i18n.PayMP.cta}
        </Button>
      </PayButtonAction>
    </>
  );
};

PayMP.propTypes = {
  goBack: PropTypes.func.isRequired,
};

export default PayMP;
