import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import {
  Box, Button, Grid, Typography, useMediaQuery,
} from '@material-ui/core';
import html2canvas from 'html2canvas';
import { getQrCodePayment } from '../../../api/selfieWebService';
import QRCodePayment from '../../../components/QRCodePayment';
import i18n from '../../../common/i18n';
import PayButtonAction from './PayButtonAction';

const PayQR = ({ externalId, goBack }) => {
  const [qrValue, setQrValue] = useState();
  const isMobile = useMediaQuery('(max-width:600px)');
  const exportRef = useRef();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQr = async () => {
      try {
        const qrRes = await getQrCodePayment(externalId);
        if (qrRes.data) {
          setQrValue(qrRes.data);
        }
      } catch (error) {
        console.error('Error obteniendo QR:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchQr();
  }, [externalId]);

  const onShare = async () => {
    const canvas = await html2canvas(exportRef.current);
    const dataUrl = canvas.toDataURL('image/png');
    const blob = await (await fetch(dataUrl)).blob();
    const filesArray = [new File([blob], 'qrcode.png', { type: blob.type, lastModified: new Date().getTime() })];
    const shareData = {
      files: filesArray,
    };
    navigator.share(shareData);
  };

  return (
    <div>
      <Grid style={{ textAlign: 'left' }}>
        <Typography style={{ fontSize: '18px', fontWeight: '600' }}>
          {i18n.payInstallmentQRCodeSubtileNew}
        </Typography>
        <Box m={2} />
        <Grid style={{ textAlign: 'left' }}>
          <Grid item>
            {qrValue && (
              <Box ref={exportRef}>
                <QRCodePayment value={qrValue} loading={loading} />
              </Box>
            )}
            <Box m={2} />
            <>
              <Typography>
                {i18n.payInstallmentQRCodeShareImageMessage}
              </Typography>
              <Box m={2} />
              <Typography>
                <b>{`${i18n.payInstallmentCvuForLoanSubTitleNew} 😉.`}</b>
              </Typography>
            </>
          </Grid>
        </Grid>
      </Grid>
      <Box m={2} />
      <PayButtonAction isMobile={isMobile} goBack={goBack}>
        <Button
          onClick={onShare}
          variant="outlined"
          color="primary"
          fullWidth
        >
          <Typography>
            <b>{i18n.payInstallmentQRCodeShareImageButtonNew}</b>
          </Typography>
        </Button>
      </PayButtonAction>
    </div>
  );
};

PayQR.propTypes = {
  externalId: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default PayQR;
