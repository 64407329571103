import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PayInstallmentRoutesList } from '../../utils/menuAndRoutesUtil';
import { usePayInstallmentContext } from '../../contexts/PayInstallmentContext';
import { STATUS_INSTALLMENT_OVERDUE, STATUS_INSTALLMENT_PARTIAL, STATUS_INSTALLMENT_PENDING } from '../../constants/payInstallmentConstants';

const usePayInstallmentSummaryController = () => {
  const history = useHistory();
  const { selectedLoan } = usePayInstallmentContext();

  const [loading, setLoading] = useState(true);
  const [installmentsToPay, setInstallmentsToPay] = useState(true);
  // TODO: ver el monto a pagar
  // const [totalAmountToPay, setTotalAmountToPay] = useState();

  const goBack = () => {
    history.push(PayInstallmentRoutesList.home);
  };

  const init = () => {
    setLoading(true);
    if (selectedLoan === undefined) {
      goBack();
    }
    // Me quedo con las cuotas que estan pendientes de pago a la fecha
    // TODO: ver bien esta logica, ya que por ejemplo la cuota actual vence recien el mes que viene
    const lala = selectedLoan?.loanInfo.installments.filter(
      (installment) =>
        (installment.number <= selectedLoan.loanInfo.currentInstallment.number)
        && (
          installment.status === STATUS_INSTALLMENT_PENDING
          || installment.status === STATUS_INSTALLMENT_OVERDUE
          || installment.status === STATUS_INSTALLMENT_PARTIAL
        ),
    );
    console.log(lala);
    setInstallmentsToPay(lala);
    setLoading(false);
  };

  useEffect(() => { init(); }, []);

  return {
    loading,
    installmentsToPay,
    goBack,
  };
};

export default usePayInstallmentSummaryController;
