import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import CopyIcon from '../../assets/imgs/copy-icon-new.svg';
import Toast from './Toast';

const useStyles = makeStyles({
  copyButton: {
    padding: 0,
    marginLeft: '5px',
    marginBottom: '3px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const CopyToClipboardButton = ({ value, width }) => {
  const [openToast, setOpenToast] = useState(false);
  const classes = useStyles();
  const copyToClipboard = () => {
    setOpenToast(false);
    setOpenToast(true);
    navigator.clipboard.writeText(value);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  return (
    <div className={classes.buttonContainer}>
      <IconButton onClick={copyToClipboard} className={classes.copyButton}>
        <img src={CopyIcon} alt="cpy" width={width} />
      </IconButton>
      <Toast
        messageOnSuccess="Copiado al portapapeles"
        open={openToast}
        onClose={handleCloseToast}
      />
    </div>
  );
};

CopyToClipboardButton.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.string,
};

CopyToClipboardButton.defaultProps = {
  width: '20px',
};

export default CopyToClipboardButton;
