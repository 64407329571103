import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Box, Button, useMediaQuery,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import CopyToClipboardButton from '../../../components/common/CopyToClipboardButton';
import i18n from '../../../common/i18n';
import { getCvuPayment } from '../../../api/selfieWebService';
import PayButtonAction from './PayButtonAction';
import Toast from '../../../components/common/Toast';
import PaymentMethodExplanation from '../../../components/PaymentMethodExplanation';

const PayTransfer = ({ externalId, goBack }) => {
  const [cvuToPay, setCvuToPay] = useState(undefined);
  const [toast, setToast] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchCvu = async () => {
      try {
        const res = await getCvuPayment(externalId);
        if (res.data) {
          setCvuToPay(res.data);
        }
      } catch (error) {
        console.error('Error obteniendo CVU:', error);
      }
    };
    fetchCvu();
  }, [externalId]);

  const handleCopyCvu = () => {
    setToast(false);
    setToast(true);
    navigator.clipboard.writeText(cvuToPay);
  };

  const handleCloseToastCVU = () => {
    setToast(false);
  };

  return (
    cvuToPay ? (
      <div>
        <Grid style={{ textAlign: 'left' }}>
          <Typography style={{ fontSize: '16px' }}>
            <b>{i18n.payInstallmentCvuForLoanTitle}</b>
          </Typography>
          <Box m={2} />
          <Box style={{ backgroundColor: '#F5F5F5', marginTop: '12px', padding: '16px' }}>
            <Typography style={{
              fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
            }}
            >
              <span>
                <b>CVU: </b>
                {cvuToPay}
              </span>
              <CopyToClipboardButton value={cvuToPay} />
            </Typography>
          </Box>
          <Box m={2} />
          <Typography style={{ fontSize: '15px' }}>
            <b>{`${i18n.payInstallmentCvuForLoanSubTitleNew} 😉.`}</b>
          </Typography>
          <Box m={2} />
          <Alert icon={<InfoIcon style={{ color: 'rgb(232, 244, 253)', backgroundColor: '#2196f3', borderRadius: '50%' }} />} severity="info">
            <Typography style={{ fontSize: '15px' }}>
              <b>{i18n.payInstallmentCvuForLoan_1}</b>
            </Typography>
            <Typography style={{ fontSize: '14px' }}>
              {i18n.payInstallmentCvuForLoan_2}
            </Typography>
          </Alert>
        </Grid>
        <Box m={2} />
        <PayButtonAction isMobile={isMobile} goBack={goBack}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleCopyCvu}
          >
            <b>{i18n.copyCVU}</b>
          </Button>
          <Toast
            messageOnSuccess="Copiado al portapapeles"
            open={toast}
            onClose={handleCloseToastCVU}
          />
        </PayButtonAction>
      </div>
    )
      : (<PaymentMethodExplanation paymentMethodKey={i18n.payInstallmentBankTrxKey} />)
  );
};

PayTransfer.propTypes = {
  externalId: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default PayTransfer;
