import { useState } from 'react';
import {
  Box, Divider, FormControlLabel, makeStyles, Radio, RadioGroup,
  Typography,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { capitalizeSentence, fillMessageWith, mapCodeToDescription } from '../../../utils/functionsUtil';
import i18n from '../../../common/i18n';
import { moneyFormatter } from '../../../utils/formatterUtil';
import { usePayInstallmentContext } from '../../../contexts/PayInstallmentContext';
import { COLOR_BY_STATUS, DESCRIPTION_BY_STATUS } from '../../../constants/payInstallmentConstants';
import { PRODUCTS_IDS } from '../../../constants/selfieConstants';

const useStyles = makeStyles(() => ({
  selected: {
    backgroundColor: '#E720640D',
    border: '1px solid #E72064 !important',
  },
}));

const LoanCardList = () => {
  const classes = useStyles();
  const { loanList, selectedLoan, setSelectedLoan } = usePayInstallmentContext();
  const [selectedValue, setSelectedValue] = useState(selectedLoan?.loanInfo.loanId.toString() || '');

  const handleChange = (id) => {
    setSelectedValue(id.toString());
    setSelectedLoan(loanList.find((loan) => loan.loanInfo.loanId === id));
  };

  const resolveDescription = (loan) => {
    let description;
    if (loan.loanInfo.productId === PRODUCTS_IDS.REVOLVING_LINE) {
      description = i18n.PayInstallmentPage.revolvingLine;
    } else {
      description = fillMessageWith(
        i18n.PayInstallmentPage.loanCardTitle,
        fillMessageWith(
          mapCodeToDescription(loan.loanInfo.subproductId, i18n.subproductsGroup),
          capitalizeSentence(loan.loanInfo.commerceName),
        ),
        moneyFormatter(loan.loanInfo.totalAmount),
      );
    }
    return description;
  };

  return (
    <RadioGroup value={selectedValue} onChange={(event) => handleChange(event.target.value)}>
      {loanList.map((loan) => (
        <Box
          key={loan.loanInfo.loanId}
          onClick={() => handleChange(loan.loanInfo.loanId)}
          style={{
            border: '1px solid #E0E0E0',
            borderRadius: '4px',
            padding: '8px 16px',
            paddingTop: '0px',
            marginBottom: '12px',
            cursor: 'pointer',
          }}
          className={selectedValue === loan.loanInfo.loanId.toString() ? classes.selected : ''}
        >
          <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
            <FormControlLabel
              value={loan.loanInfo.loanId.toString()}
              control={<Radio color="primary" />}
              style={{ marginRight: '0px' }}
            />
            <Box style={{ width: '100%' }}>
              <Typography align="left" style={{ fontSize: '14px', fontWeight: 700, marginTop: '12px' }}>
                {resolveDescription(loan)}
              </Typography>
              <Divider style={{ margin: '8px 0px' }} />
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  {loan.loanInfo.productId === PRODUCTS_IDS.REVOLVING_LINE ? (
                    <>
                      <Typography align="left" style={{ fontSize: '16px', fontWeight: 700, color: '#E72064' }}>
                        {moneyFormatter(loan.loanInfo.revolvingLineAmount)}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography align="left" style={{ fontSize: '12px' }}>
                        {fillMessageWith(i18n.PayInstallmentPage.installments, loan.loanInfo.installmentCount)}
                      </Typography>
                      <Typography align="left" style={{ fontSize: '16px', fontWeight: 700, color: '#E72064' }}>
                        {moneyFormatter(loan.loanInfo.averageInstallmentAmount)}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box>
                  <Typography align="right" style={{ fontSize: '12px' }}>
                    <FiberManualRecordIcon
                      style={{
                        marginRight: '4px', fontSize: '10px', color: COLOR_BY_STATUS[loan.status],
                      }}
                    />
                    {DESCRIPTION_BY_STATUS[loan.status]}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </RadioGroup>
  );
};

export default LoanCardList;
