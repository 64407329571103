import { PropTypes } from 'prop-types';
import { Box, Button } from '@material-ui/core';
import usePayInstallmentStyles from '../PayInstallmentStyles';
import i18n from '../../../common/i18n';

const PayButtonAction = ({ children, isMobile, goBack }) => {
  const classes = usePayInstallmentStyles();

  return (
    isMobile ? (
      <Box className={classes.ctaContainer}>
        {children}
        <Button
          variant="text"
          color="primary"
          fullWidth
          onClick={goBack}
          style={{ marginTop: '8px' }}
        >
          <b>{i18n.PayInstallmentPage.backCTA}</b>
        </Button>
      </Box>
    ) : (
      <Box
        className={classes.ctaContainer}
        style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
        }}
      >
        <Button
          variant="text"
          color="primary"
          fullWidth
          onClick={goBack}
        >
          <b>{i18n.PayInstallmentPage.backCTA}</b>
        </Button>
        {children}
      </Box>
    )
  );
};

PayButtonAction.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default PayButtonAction;
