import { useState } from 'react';
import {
  Box, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography, Radio,
} from '@material-ui/core';
import i18n from '../../common/i18n';
import Header from '../../components/common/Header';
import { usePayInstallmentContext } from '../../contexts/PayInstallmentContext';
import { PRODUCTS_IDS } from '../../constants/selfieConstants';
import { capitalizeSentence, fillMessageWith, mapCodeToDescription } from '../../utils/functionsUtil';
import usePayInstallmentSummaryController from '../../customHook/controller/usePayInstallmentSummaryController';
import Loading from '../../components/common/Loading';
import { moneyFormatter } from '../../utils/formatterUtil';
import {
  PAYMENT_METHOD_CASH, PAYMENT_METHOD_MP, PAYMENT_METHOD_QR, PAYMENT_METHOD_TRANSFER, PAYMENT_METHODS,
} from '../../constants/payInstallmentConstants';
import usePayInstallmentStyles from './PayInstallmentStyles';
import PayTransfer from './components/PayTransfer';
import PayQR from './components/PayQR';
import PayButtonAction from './components/PayButtonAction';
import PayCash from './components/PayCash';
import PayMP from './components/PayMP';

const PayInstallmentSummaryPage = () => {
  const classes = usePayInstallmentStyles();
  const { selectedLoan } = usePayInstallmentContext();
  const { loading, goBack } = usePayInstallmentSummaryController();
  const [paymentMethod, setPaymentMethod] = useState('');

  const resolveLoanDescription = (loan) => {
    let description;
    if (loan.loanInfo.productId === PRODUCTS_IDS.REVOLVING_LINE) {
      description = i18n.PayInstallmentPage.revolvingLine;
    } else {
      description = fillMessageWith(
        mapCodeToDescription(loan.loanInfo.subproductId, i18n.subproductsGroup),
        capitalizeSentence(loan.loanInfo.commerceName),
      );
    }
    return description;
  };

  if (loading) return <Loading />;

  return (
    <>
      <Header title={i18n.PayInstallmentPage.title} />
      <Grid container style={{ marginTop: '20px', paddingLeft: '24px', paddingRight: '24px' }} justifyContent="center">
        <Grid item xs={12} md={8} className={classes.gap}>
          <Typography style={{ fontSize: '20px', fontWeight: 700 }}>
            {i18n.PayInstallmentSummaryPage.title}
          </Typography>
          <Box style={{ backgroundColor: '#F5F5F5', marginTop: '12px', padding: '16px' }}>
            <Typography align="left" style={{ fontSize: '14px' }}>{resolveLoanDescription(selectedLoan)}</Typography>
            <Typography align="left" style={{ fontSize: '12px', fontWeight: 700 }}>
              {fillMessageWith(
                i18n.PayInstallmentSummaryPage.loanNumber,
                selectedLoan.loanInfo.externalId,
              )}
            </Typography>
            <Divider style={{ margin: '8px 0px' }} />
            {selectedLoan.loanInfo.installments.map((installment) => (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {/* TODO: ver como mostrar el listado de cuotas a pagar */}
                <Typography>
                  {fillMessageWith(
                    i18n.PayInstallmentSummaryPage.installmentNumber,
                    installment.number,
                  )}
                </Typography>
                <Typography>
                  {moneyFormatter(installment.totalAmount)}
                </Typography>
              </Box>
            ))}
            <Divider style={{ margin: '8px 0px' }} />
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography align="left" style={{ fontSize: '14px', fontWeight: 700 }}>
                {i18n.PayInstallmentSummaryPage.totalToPay}
              </Typography>
              {/* TODO: ver el monto a pagar */}
              <Typography align="right" style={{ fontSize: '16px', fontWeight: 700, color: '#E72064' }}>
                {moneyFormatter(selectedLoan.loanInfo.totalAmount)}
              </Typography>
            </Box>
          </Box>
          <Typography align="left" style={{ fontSize: '16px', marginTop: '24px', marginBottom: '12px' }}>
            {i18n.PayInstallmentSummaryPage.choosePaymentMethod}
          </Typography>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="form-select-label">{i18n.PayInstallmentSummaryPage.paymentMethod}</InputLabel>
            <Select
              style={{ textAlign: 'start' }}
              name="reasonTypeSelect"
              onChange={(event) => setPaymentMethod(event.target.value)}
              labelId="form-select-label"
              id="form-select"
              label={i18n.PayInstallmentSummaryPage.paymentMethod}
              fullWidth
              value={paymentMethod}
              renderValue={(selected) => {
                const selectedOption = PAYMENT_METHODS.find((opt) => opt.id === selected);
                return selectedOption ? selectedOption.description : i18n.PayInstallmentSummaryPage.paymentMethod;
              }}
            >
              {PAYMENT_METHODS.map((method, index) => (
                <MenuItem key={method.id} value={method.id} divider={index < PAYMENT_METHODS.length - 1}>
                  <Radio
                    size="small"
                    value={method.id}
                    checked={paymentMethod === method.id}
                    color="primary"
                  />
                  {method.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* -- Maquetado Pago TRANSFERENCIA -- */}
          <Box m={2} />
          <FormControl fullWidth variant="outlined">
            {paymentMethod === PAYMENT_METHOD_TRANSFER && (
              <PayTransfer externalId={selectedLoan.loanInfo.externalId} goBack={goBack} />
            )}
          </FormControl>
          {/* -- Maquetado Pago QR -- */}
          {paymentMethod === PAYMENT_METHOD_QR && (
            <PayQR externalId={selectedLoan.loanInfo.externalId} goBack={goBack} />
          )}
          {/* PAGO EFECTIVO */}
          {paymentMethod === PAYMENT_METHOD_CASH && (
            <PayCash goBack={goBack} />
          )}
          {/* PAGO MP */}
          {paymentMethod === PAYMENT_METHOD_MP && (
            <PayMP goBack={goBack} />
          )}
          {/* -- ACCIONES -- */}
          {!paymentMethod && (
            <PayButtonAction goBack={goBack}> </PayButtonAction>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PayInstallmentSummaryPage;
