export const STATUS_INSTALLMENT_PENDING = 'PENDING';
export const STATUS_INSTALLMENT_OVERDUE = 'OVERDUE';
export const STATUS_INSTALLMENT_PARTIAL = 'PARTIAL';

export const STATUS_TO_PAY = 'TO_PAY';
export const STATUS_UP_TO_DATE = 'UP_TO_DATE';
export const STATUS_DEBT = 'DEBT';

export const COLOR_BY_STATUS = {
  [STATUS_TO_PAY]: '#F5A623',
  [STATUS_UP_TO_DATE]: '#02C66A',
  [STATUS_DEBT]: '#D0013F',
};

export const DESCRIPTION_BY_STATUS = {
  [STATUS_TO_PAY]: 'A pagar',
  [STATUS_UP_TO_DATE]: 'Al día',
  [STATUS_DEBT]: 'En deuda',
};

export const PAYMENT_METHOD_TRANSFER = 'TRANSFER';
export const PAYMENT_METHOD_TD = 'TD';
export const PAYMENT_METHOD_QR = 'QR';
export const PAYMENT_METHOD_PMC = 'PMC';
export const PAYMENT_METHOD_MP = 'MP';
export const PAYMENT_METHOD_CASH = 'CASH';

export const PAYMENT_METHODS = [
  { id: PAYMENT_METHOD_TRANSFER, description: 'Transferencia' },
  { id: PAYMENT_METHOD_TD, description: 'Tarjeta de débito' },
  { id: PAYMENT_METHOD_QR, description: 'Pago con QR' },
  { id: PAYMENT_METHOD_PMC, description: 'Pago mis cuentas' },
  { id: PAYMENT_METHOD_MP, description: 'Mercado Pago' },
  { id: PAYMENT_METHOD_CASH, description: 'Efectivo' },
];
